<template>
  <div v-if="packagesData"
       class="flex flex-col items-center w-2/3 lg:w-full lg:grid lg:grid-cols-3 flex-wrap gap-6 lg:gap-0"
  >
    <div
        v-for="(packageItem,index) in packagesData" :key="index"
        class="relative lg:w-auto border border-black flex flex-col col-span-1 justify-items-center rounded
        lg:group-hover:scale-101 px-3 pt-3 pb-6 space-y-3 lg:space-y-6"
        :class="{
        'lg:mr-6': index===0,
        'lg:ml-6': index===2,
        'lg:my-9 lg:pt-0 lg:pb-6 w-10/12': index!=1,
        'lg:px-6 lg:pt-3 lg:pb-6 w-full': index===1
      }"
    >
      <div class="absolute w-full text-right justify-end -mt-6 -mr-3"
           :class="{'lg:-mt-6 lg:-ml-3 ':index===1,
        'lg:-mt-3 lg:-ml-0':index===2,
        }"
      >
        <Badge v-if="index===1" tagName="span" variant="badgeInfo">
          {{ trans('LABEL:SubscriptionTheMostPopular') }}
        </Badge>
        <Badge v-if="index===2" tagName="span" variant="badgeSuccess">
          {{ trans('LABEL:SubscriptionTheCheapest') }}
        </Badge>
      </div>

      <div class="font-semibold text-center text-base"
           :class="{'text-xl': index===1}"
      >
        {{ trans(packageItem.name) }}
      </div>
      <hr class="border-grayedHR">
      <div class="text-center text-xl flex flex-col justify-center gap-3"
           :class="{
            'lg:gap-6':index===1
          }"
      >
        <div class="flex flex-col"
             :class="{'gap-3':index===1}"
        >
          <div class="text-center text-4xl" :class="{'text-5xl': index===1}" v-if="packageItem.salePrice && packageItem.shouldApplyDiscount">
            <span class="font-light text-3xl text-redAccent line-through" :class="{'text-4xl': index===1}">{{ packageItem.priceFormatted }}</span>
            <span class="text-base text-redAccent" :class="{'text-xl': index===1}"> {{trans('LABEL:currency')}}</span>
          </div>
          <div>
            <span v-if="packageItem.salePrice && packageItem.shouldApplyDiscount" class="font-light text-4xl text-redAccent" :class="{'text-5xl': index===1}">{{ packageItem.salePriceFormatted }}</span>
            <span v-else class="font-light text-4xl text-redAccent" :class="{'text-5xl': index===1}">{{ packageItem.priceFormatted }}</span>
            <span class="text-base text-redAccent" :class="{'text-xl': index===1}"> {{trans('LABEL:currency')}}</span>
          </div>
        </div>
      </div>
      <div class="text-center text-black text-base" :class="{'text-xl': index===1}">
        {{trans('LABEL:daysFor') }} {{ packageItem.days}} {{trans('LABEL:days') }}
      </div>
      <div class="text-center text-base text-grayed ":class="{'text-xl': index===1}">
        <div v-if="packageItem.salePrice && packageItem.shouldApplyDiscount">{{packageItem.salePricePerDayFormatted}}</div>
        <div v-else>{{packageItem.pricePerDayFormatted}}</div>
      </div>
      <div class="text-center pt-1.5 lg:pt-0">
        <Button
            :variant="{'buttonRedOutlined': index!=1, 'buttonRedLarge': index===1}"
            :custom-class="'uppercase'"
            @clicked="createPaymentAction(packageItem.id)"
            :loading="subscriptionPending === packageItem.id"
        >
          {{ trans('LABEL:Choose') }}
        </Button>
      </div>
    </div>
  </div>
  <div v-else class="w-full flex justify-center">
    <Loader small no-border/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Button from "@/utils/crud/components/Button";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Badge from "@/utils/crud/components/Badge"
import TextField from "@/utils/crud/components/field-types/Text";
import Loader from "@/views/Loader";

export default {
  name: "SubscriptionPackages",
  components: {Button, Badge, BadgeFrame, Loader, TextField},
  data() {
    return {
      partnerCode: {},
    }
  },
  computed: {
    ...mapGetters('subscriptions', ['packagesData', 'subscriptionPending'])
  },
  methods: {
    ...mapActions('subscriptions', ['createPaymentRequest']),

    createPaymentAction(packageId) {
      let data = {
        packageId: packageId,
        partnerCode: this.partnerCode[packageId],
      };
      this.createPaymentRequest(data)
    }
  }
}
</script>



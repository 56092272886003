<template>
  <PageContainer v-if="userData">
    <PageTitle :breadcrumb="{name: 'settings'}" class="lg:w-main-container">
      {{ trans('BUTTON:BackToSettings') }}
    </PageTitle>
    <t-card class="w-full lg:w-main-container">
      <div class="flex flex-col space-y-4">
        <div class="text-base font-semibold text-black  leading-normal">
          {{ trans('LABEL:SubscriptionChoosePackage') }}
        </div>
        <Hint v-if="userData.isSubscriptionActive" variant="Success">
          {{ trans('LABEL:SubscriptionTheBestOffer') }}
        </Hint>
        <div class="flex flex-col items-center">
          <SubscriptionPackages />
        </div>
      </div>
    </t-card>
  </PageContainer>
</template>

<script>
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Hint from "@/views/content/components/Hint"

import {mapActions, mapGetters} from "vuex";
import SubscriptionPackages from "@/views/content/subscriptions/components/SubscriptionPackages";

export default {
  name: "SubscriptionView",
  components: {SubscriptionPackages, Hint, PageTitle, PageContainer},
  computed: {
    ...mapGetters('account', ['userData'])
  },
  methods: {
    ...mapActions('subscriptions', ['fetchPackagesData'])
  },
  mounted() {
    this.fetchPackagesData();
  }

}
</script>


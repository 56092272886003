<template>
  <div
    class="w-full lg:w-page-width mx-auto flex lg:min-h-screenMinusHeader justify-center items-start lg:mb-6"
    :class="isChatOpenOnMobile()?'pt-0 pb-0 h-100dvh':'pt-16 pb-3'"
  >
    <div class="w-full flex flex-col items-center"
      :class="{'lg:my-auto ':centerVertically}"
    >
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
import {isChatOpenOnMobile} from "@/common/helpers/utils";

export default {
  name: "PageContainer",
	methods: {isChatOpenOnMobile},
  props: {
    centerVertically: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style>
.h-100dvh {
	//height: 100dvh;
	//min-height: 100dvh;
	//min-height: -webkit-fill-available;
}
</style>
